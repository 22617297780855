import { useAxios } from "../../hooks/useAxios";
export const brands = {
    state: {
        brands: [],
        subBrands: [],
        brandSelected: null,
        idbrandSelected: null,
        idSubBrandSelected: null
    },
    mutations: {
        setBrands(state, payload) {
            state.brands = payload;
        },
        setSubBrands(state, payload) {
            state.subBrands = payload;
        },
        setBrandSelected(state, payload) {
            state.brandSelected = payload;
        },
        setIdBrandSelected(state, payload) {
            state.idbrandSelected = payload;
        },
        setIdSubBrandSelected(state, payload) {
            state.idSubBrandSelected = payload;
        },
    },
    actions: {
        async setBrandsAction({ commit }) {
            const { data, status, snack } = await useAxios("/getBrands", "get", true, null, false);
            if (data?.brands) {
                commit('setBrands', data?.brands);
                commit('setIdBrandSelected', data?.brands[0].id);
            }
            return { status, snack };
        },
        async setSubBrandsAction({ commit }) {
            const { data, status, snack } = await useAxios("/getSubBrands", "get", true, null, false);
            commit('setSubBrands', data?.brands);
            return { status, snack };
        },
        async deleteBrandAction({ commit, state }, payload) {
            const { status, snack } = await useAxios(`/deleteBrand/${payload}`, "put", true, null, true);
            if (status >= 200 && status < 300) {
                const newState = state.brands.filter((brand) => {
                    return brand.id !== payload;
                });
                commit('setBrands', newState);
            }
            return { status, snack };
        },
        async setBrandSelectedAction({ commit }, payload) { // PAYLOAD -> IDTYPESELECTED
            const { data, status, snack } = await useAxios(`getBrand/${payload}`, "get", true, null, false);
            if (status >= 200 && status < 300) {
                commit('setBrandSelected', data.brand);
            }
            return { status, snack };
        },
        async createBrand({ commit }, payload) {
            const { status, snack } = await useAxios(
                "/createBrand",
                "post",
                true,
                payload,
                true
            );
            return { status, snack }
        },
        async updateBrand({ commit }, payload) {
            const { snack, status } = await useAxios(
                `updateBrand/${payload.id}`,
                "put",
                true,
                payload.data,
                true
            );
            return { snack, status };
        },
        removeBrandSelectedAction({ commit }) {
            commit('setBrandSelected', null);
        },
        setIdBrandSelected({ commit }, payload) {
            commit('setIdBrandSelected', payload);
        },
        setIdSubBrandSelected({ commit }, payload) {
            commit('setIdSubBrandSelected', payload);
        }
    },
    getters: {
        getBrands(state) {
            return state.brands.map((brand) => {
                return {
                    value: brand.id,
                    text: brand.description,
                };
            });
        },
        getSubBrands(state) {
            const subBrands = state.subBrands.filter((subBrand) => {
                return subBrand.get_brand.id === state.idbrandSelected
            });
            return subBrands.map((subBrand) => {
                return {
                    value: subBrand.id,
                    text: subBrand.description,
                };
            });
        },
    },
};
