import useSnackBar from "../hooks/useSnackBar";
import request from "../helpers/axiosinstance";
import store from '../store';

const setStoreEndRequest = async (message = "Error Internal Server", color = "red") => {
    const textSnackBar = message || "Internal Server Error";
    const colorSnackBar = color || "red";
    await store.dispatch('setSnackbarMessageAction', textSnackBar);
    await store.dispatch('setSnackbarColorAction', colorSnackBar);    
    return true;
}

export const useAxios = async (url, method, protectedRequest, formData = null, showSanckBarTry = false) => {
    try {
        const response = await request(url, protectedRequest, method, formData);
        const { message, color, snackbar, status } = useSnackBar(response);
        const data = response.data;
        let snack = snackbar;        
        if (!showSanckBarTry && status >= 200 && status < 300) {
            snack = false;
        }
        if (status === 401 && protectedRequest) { // NO AUTORIZADO - TOKEN VENCIDO
            await store.dispatch('logOut');
            return 
        }
        const endRequest = setStoreEndRequest(message, color);
        if (!endRequest) {
            return;
        }
        return { data, status, snack };
    } catch (error) {
        const { message, color, snackbar, status } = useSnackBar(error.response);
        if (message === 'Unauthenticated.') {            
            await store.dispatch('logOut');
        }
        if (status === 401 && protectedRequest) { // NO AUTORIZADO - TOKEN VENCIDO
            await store.dispatch('logOut');
            return 
        }
        const snack = snackbar;
        const endRequest = setStoreEndRequest(message, color);        
        if (!endRequest) {
            return;
        }
        return { status, snack };
    }
}