
export const documents = {
    state: {
        documentsSelected: [],        
    },
    mutations: {                
        setDocumentsSelected(state, payload) {
            state.documentsSelected = payload;
        },
    },
    actions: {        
        setDocumentsSelectedAction({ commit }, payload) {                        
            commit('setDocumentsSelected', payload);            
        },
        addDocumentsSelectedAction({ commit, state }, payload) {                        
            commit('setDocumentsSelected', [...state.documentsSelected, payload]);            
        },        
        removeDocumentsSelectedAction({ commit }) {
            commit('setDocumentsSelected', []);
        },
        removeDocumentSelectedByIndexAction({ commit, state }, payload) {
            const documents=state.documentsSelected;
            documents.splice(payload, 1);
            commit('setDocumentsSelected', documents);            
        },        
    },
    getters: {
    },
};
