import { useAxios } from "../../hooks/useAxios";
export const users = {
    state: {
        users: [],
        userSelectedData: null,
        userSelected: '',
        usersSelected: [],
    },
    mutations: {
        setUsers(state, payload) {
            state.users = payload;
        },
        setUserSelectedData(state, payload) {
            state.userSelectedData = payload;
        },
        setUserSelected(state, payload) {
            state.userSelected = payload;
        },
        setUsersSelected(state, payload) {
            state.usersSelected = payload;
        },
    },
    actions: {
        async getAllUsersAction({ commit }) {
            const { status, data, snack } = await useAxios("/getUsers", "get", true, null, false);
            if (status >= 200 && status < 300) {
                commit('setUsers', data?.users || []);
            }
            return { status, data, snack }
        },
        async changeStateUserAction({ commit, state }, payload) {
            const { status, snack } = await useAxios(`/${payload.url}/${payload.id}`, "put", true, null, true);
            if (status >= 200 && status < 300) {
                const response = state.users.map((user) => {
                    if (user.id === payload.id) {
                        user.state = payload.newState;
                    }
                    return user;
                });
                commit('setUsers', response);
            }
            return { status, snack }
        },
        async setUserSelectedDataAction({ commit }, payload) {
            const { status, snack, data } = await useAxios(`getUser/${payload}`, "get", true, null, false);
            if (status >= 200 && status < 300) {
                commit('setUserSelectedData', data?.users);
            }
            return { status, snack }
        },
        async updateUserNoAdmin({ commit }, payload) {
            const { snack, status } = await useAxios(`/updateUserNoAdmin/${payload.id}`, "put", true, payload.data, true);
            return { snack, status }
        },
        async resetPasswordProfile({ commit }, payload) {
            const { snack, status } = await useAxios(`/resetPasswordProfile/${payload.id}`, "put", true, payload.data, true);
            return { snack, status }
        },
        setUserSelectedAction({ commit }, payload) {
            commit('setUserSelected', payload);
        },
        setUsersSelectedAction({ commit, state }, payload) {
            const usersSelected = [...state.usersSelected, payload]
            commit('setUsersSelected', usersSelected);
        },
        async createToken({ commit }, payload) {
            const { snack, status } = await useAxios(`/createToken`, "post", false, payload, true);
            return { snack, status }
        },
        async resetPassword({ commit }, payload) {
            const { status, snack } = await useAxios(`/resetPassword`, "post", false, payload, true);
            return { snack, status }
        },
        async register({ commit }, payload) {
            const { status, snack } = await useAxios("/register", "post", true, payload, true);
            return { status, snack };
        },
        async updateUser({ commit }, payload) {
            const { snack, status } = await useAxios(`updateUser/${payload.id}`, "put", true, payload.data, true);
            return { status, snack };
        },
        removeUserSelectedAction({ commit }) {
            commit('setUserSelectedData', null);
        },
        removeUsersSelectedAction({ commit }) {
            commit('setUsersSelected', []);
        },
        removeUsersSelectedByIndexAction({ commit, state }, payload) {
            const newState = state.usersSelected.filter((person) => person != payload.id);
            commit('setUsersSelected', newState);
        }
    },
    getters: {
        getActiveUsersWithoutSession(state) {
            return function (idUserSession) {
                return state.users.filter((user) => {
                    return user.state === 2 && user.id !== idUserSession; // Activos
                });
            };
        },
        getActiveUsers(state) {
            return state.users.filter((user) => {
                return user.state === 2; // Activos                
            });
        },
        getActiveUsersToSelect(state) {
            const activeUsers = state.users.filter((user) => {
                return user.state === 2; // Activos                
            });
            return activeUsers.map((user) => {
                return {
                    value: user.id,
                    text: user.get_person.name,
                };
            });
        },
        getActiveUserToSelectRestricted(state) { // For Project Persons                     
            const activeUsers = state.users.filter((user) => {
                return user.state === 2 && state.usersSelected.indexOf(user.get_person.id) === -1; // Activos                
            });
            return activeUsers.map((user) => {
                return {
                    value: user.get_person.id,
                    text: user.get_person.name,
                };
            });
        },
        getInactiveUsers(state) {
            return state.users.filter((user) => {
                return user.state === 1; // Inactivos                
            });
        },
        getUserById(state) {
            return function (id) {
                return state.users.find((user) => user.get_person.id === id);
            };
        }
    }
};