import { useAxios } from "../../hooks/useAxios";

export const roles = {
    state: {
        roles: [],
    },
    mutations: {
        setRoles(state, payload) {
            state.roles = payload;
        },
    },
    actions: {
        setRolesAction({ commit }, payload) {
            commit('setRoles', payload);
        },
        async createRol({ commit }, payload) {
            const { status, snack } = await useAxios("/createRol", "post", true, payload, true);
            return { status, snack };
        },
        async updateRol({ commit }, payload) {
            const { snack, status } = await useAxios(`updateRol/${payload.id}`, "put", true, payload.data, true);
            return { status, snack };
        }
    },
    getters: {
    },
};