import { useAxios } from "../../hooks/useAxios";

export const localities = {
  state: {
    localities: [],
  },
  mutations: {
    setLocalities(state, payload) {
      state.localities = payload;
    },
  },
  actions: {
    async setLocalitiesActions({ commit }) {
      const { data, status, snack } = await useAxios(
        "/getLocalities",
        "get",
        true,
        null,
        false
      );
      if (data?.data) {
        commit("setLocalities", data.data);
      }
      return { status, snack };
    },
  },
  getters: {
    getCountries(state) {
      return state.localities.map((el) => {
        return {
          value: el.country.id,
          text: el.country.name,
        };
      });
    },
    getProvincesByCountryId(state) {
      return function(id) {
        const provincesSelected = state.localities.find((item) =>
          id ? item.country.id === id : typeof item.country.id === "number"
        );

        return provincesSelected?.provinces.map((el) => {
          return {
            value: el.province.id,
            text: el.province.name,
          };
        });
      };
    },
    getLocalitiesByProvinceId(state) {
      return function(idCountry, idProvince) {
        const provincesSelected = state.localities.find(
          (item) => idCountry ? item.country.id === idCountry : typeof item.country.id === "number"
        );
        const localitiesSelected = provincesSelected?.provinces.find(
          (item) =>  idProvince ? item.province.id === idProvince : typeof item.province.id === "number"
        );
        return localitiesSelected?.localities.map((el) => {
          return {
            value: el.id,
            text: el.name,
          };
        });
      };
    },
  },
};
