export const protectedRoutes = [
    {
        path: '/',
        component: () => import('../views/AdminShell.vue'),        
        meta: { protectedRoute: true },
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('../views/Home.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/users/list',
                name: 'Users',
                component: () => import('../views/users/UsersView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/users/create',
                name: 'UsersCreate',
                component: () => import('../views/users/UsersCreateView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/users/edit/:iduser',
                name: 'UsersEdit',
                component: () => import('../views/users/UsersEditView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/users/profile/:iduser',
                name: 'UsersProfile',
                component: () => import('../views/users/UsersProfileView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/profile',
                name: 'Profile',
                component: () => import('../views/profile/Profile.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/roles/list',
                name: 'Roles',
                component: () => import('../views/roles/RolesView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/roles/create',
                name: 'RolesCreate',
                component: () => import('../views/roles/RolesCreateView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/roles/edit/:idrol',
                name: 'RolesEdit',
                component: () => import('../views/roles/RolesEditView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/brands/list',
                name: 'Brands',
                component: () => import('../views/brands/BrandsView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/brands/create',
                name: 'BrandCreate',
                component: () => import('../views/brands/BrandCreateView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/brands/edit/:idbrand',
                name: 'BrandEdit',
                component: () => import('../views/brands/BrandEditView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/products/list',
                name: 'Products',
                component: () => import('../views/products/ProductsView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/products/create',
                name: 'ProductCreate',
                component: () => import('../views/products/ProductsCreateView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/products/edit/:idproduct',
                name: 'ProductEdit',
                component: () => import('../views/products/ProductsEditView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/steps/list',
                name: 'Steps',
                component: () => import('../views/steps/StepsView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/steps/create',
                name: 'StepCreate',
                component: () => import('../views/steps/StepCreateView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/steps/edit/:idstep',
                name: 'StepEdit',
                component: () => import('../views/steps/StepEditView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/project/list',
                name: 'Project',
                component: () => import('../views/project/ProjectView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/project/create',
                name: 'ProjectCreate',
                component: () => import('../views/project/ProjectCreateView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/project/edit/:idproject',
                name: 'ProjectEdit',
                component: () => import('../views/project/ProjectEditView.vue'),
                meta: { protectedRoute: true }
            },
            {
                path: '/project/detail/:idproject',
                name: 'ProjectView',
                component: () => import('../views/project/ProjectDetailView.vue'),
                meta: { protectedRoute: true }
            },
        ]
    },
]