import { useAxios } from "../../hooks/useAxios";
export const person = {
  state: {
    typesPerson: [],
    allPermissions: [],
    typeSelected: 1,
    roleSelectedData: null
  },
  mutations: {
    setTypesPerson(state, payload) {
      state.typesPerson = payload;
    },
    setAllPermissions(state, payload) {
      state.allPermissions = payload;
    },
    settypeSelected(state, payload) {
      state.typeSelected = payload;
    },
    setRoleSelectedData(state, payload) {
      state.roleSelectedData = payload;
    },
  },
  actions: {
    async setTypesPersonAction({ commit }) {
      const { data, status, snack } = await useAxios("/getTypesPerson", "get", true, null, false);
      commit('setTypesPerson', data.types_person);
      return { status, snack };
    },
    async setAllPermissionsAction({ commit, state }) {
      if (state.allPermissions.length===0) { // SOLO HACE LA PETICION EN CASO QUE NO ESTÉ EN EL STORE YA QUE ES INFORMACION QUE NO SE PUEDE EDITAR DSD EL FRONT
        const { data, status, snack } = await useAxios("/getPermissions", "get", true, null, false);
        commit('setAllPermissions', data.permissions);
        return { status, snack };        
      }
      const res={
        status:200,
        snack:false
      }
      return res
    },
    settypeSelectedAction({ commit }, payload) { // PAYLOAD -> IDTYPESELECTED
      commit('settypeSelected', payload);
    },
    async deleteTypesPerson({ commit, state }, payload) {
      const { status, snack } = await useAxios(`/deleteRol/${payload}`, "delete", true, null, true);
      if (status >= 200 && status < 300) {
        const newState = state.typesPerson.filter((type) => {
          return type.id !== payload            
        });
        commit('setTypesPerson', newState);
      }
      return { status, snack };
    },
    async setRoleSelectedDataAction({ commit }, payload) {
      const { data, status, snack } = await useAxios(`getTypePerson/${payload}`, "get", true, null, false);
      if (status >= 200 && status < 300) {        
        await commit('setRoleSelectedData', data);        
      }
      return { status, snack };
    },
    removeRoleSelectedDataAction({ commit }) {
      commit('setRoleSelectedData', null);
    }
  },
  getters: {
    getTypesPerson(state) {
      return state.typesPerson.map((type) => {
        return {
          value: type.id,
          text: type.description,
        };
      });
    },
    getPermissionsByType(state) {
      let per = [];
      state.typesPerson.forEach(typeP => {
        if (typeP.id == state.typeSelected) {
          per = typeP.permissions.map((p) => {
            return p.name;
          });
        }
      });
      return per;
    },
  },
};
