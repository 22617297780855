import router from "../router";
const useSnackBar = (res) => {
    const data = {}
    if (res?.status == 200) { // Entra por el catch        
        data.message = res.data?.message || '';
        data.status = res.data?.status || 200;
        switch (res.data.status) {
            case 200:
                data.color = "green";
                data.type = "success";
                break;
            case 201:
                data.color = "green";
                data.type = "success";
                break;
            case 400:
                data.color = "red";
                data.type = "danger";
                break;
                case 400:
                    data.color = "red";
                    data.type = "danger";
                    break;
            case 500:
                data.color = "red";
                data.type = "danger";
                break;

            default:
                data.color = "red";
                data.type = "danger";
                break;
        }
    }
    else { // Entra por el catch
        data.message = res?.data?.message || "Internal Error Server";        
        data.color = "red";
        data.status=500;
        data.type = "danger";
    }
    data.snackbar = true;
    return data;
}
export default useSnackBar;