import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: { dark: true },
  //,{
  //     options: {
  //       customProperties: true,
  //     },
  //   themes: {
  //     light: {
  //       primary: '$primary',
  //       secondary: '$secondary',
  //       accent: '$accent',
  //       error: '$error',
  //       info: '#2196F3',
  //       success: '#006329',
  //       warning: '#FFC107',
  //       gray:'#ECECEC',
  //       transparent:'transparent',
  //     },
  //   },
  //},
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'md',
  },
});
