import axios from 'axios';
import store from '../store'

const instance = axios.create();

const request = async (url, protectedRequest, method, data = null) => {

  const config = {
    url: url,
    method: method,
    baseURL: process.env.VUE_APP_URL_API,
  }
  if (data != null) {
    config.data = data;
  }
  if (protectedRequest) {
    config.headers = {
      Authorization: `Bearer ${store.state.user.access_token}`,
    };
  }
  return await instance.request(config);
}
export default request;
