import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import links from "../helpers/links";
import {
  ui,
  users,
  person,
  brands,
  products,
  documents,
  roles,
  steps,
  projects,
  localities,
} from "./modules";
import { useAxios } from "../hooks/useAxios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    isLogged: false,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setIsLogged(state, payload) {
      state.isLogged = payload;
    },
  },
  actions: {
    async logIn({ commit }, formData) {
      const { data, status, snack } = await useAxios(
        "/login",
        "post",
        false,
        formData,
        false
      );
      if (status >= 200 && status < 300) {
        const user = data;
        commit("setUser", user);
        commit("setIsLogged", true);
        if (formData.rememberMe) {
          localStorage.setItem("user", JSON.stringify(user));
        } else {
          sessionStorage.setItem("user", JSON.stringify(user));
        }
        router.push(links.home);
      }
      return { status, snack };
    },
    async setUserSession({ commit }, payload) {
      commit("setUser", payload);
    },

    logOut({ commit }) {
      commit("setUser", null);
      commit("setIsLogged", false);
      sessionStorage.removeItem("user");
      localStorage.removeItem("user");
      router.push(links.login.login);
    },

    loadUser({ commit }) {
      if (sessionStorage.getItem("user")) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        commit("setUser", user);
        commit("setIsLogged", true);
        return true;
      } else if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        commit("setUser", user);
        commit("setIsLogged", true);
        return true;
      }
      return false;
    },
  },
  getters: {},
  modules: {
    ui: ui,
    users: users,
    persons: person,
    brands: brands,
    products: products,
    documents: documents,
    roles: roles,
    steps: steps,
    projects: projects,
    localities: localities,
  },
});
