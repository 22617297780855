export const publicRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/LoginView.vue')
    },
    {
        path: '/createToken',
        name: 'CreateTokenPass',
        component: () => import('../views/login/CreateTokenPassView.vue')
    },
    {
        path: '/resetPassword/:token',
        name: 'ResetPassword',
        component: () => import('../views/login/ResetPasswordView.vue')
    },
]