import { useAxios } from "../../hooks/useAxios";

export const steps = {
  state: {
    steps: [],
    stepSelected: null,
  },
  mutations: {
    setSteps(state, payload) {
      state.steps = payload;
    },
    setStepSelected(state, payload) {
      state.stepSelected = payload;
    },
  },
  actions: {
    setStepSelectedAction({ commit }, payload) {
      commit("setStepSelected", payload);
    },
    async getSteps({ commit }) {
      const { data, status, snack } = await useAxios(
        "/getSteps",
        "get",
        true,
        null,
        false
      );
      if (data?.steps) {
        commit("setSteps", data.steps);
      }
      return { status, snack };
    },
    async getStepsByDefaultAction({ commit }) {
      const { data, status, snack } = await useAxios(
        "/getStepsDefault",
        "get",
        true,
        null,
        false
      );
      if (data?.steps) {
        commit("setSteps", data.steps);
      }
      return { status, snack };
    },
    async getStepById({ commit }, payload) {
      const { data, status, snack } = await useAxios(
        `getStep/${payload}`,
        "get",
        true,
        null,
        false
      );
      if (status >= 200 && status < 300) {
        commit("setStepSelected", data.step);
      }
      return { status, snack };
    },
    async createStep({ commit }, payload) {
      const { status, snack } = await useAxios(
        "/createStep",
        "post",
        true,
        payload,
        true
      );
      return { status, snack };
    },
    async updateStep({ commit }, payload) {
      const { snack, status } = await useAxios(
        `updateStep/${payload.id}`,
        "put",
        true,
        payload.data,
        true
      );
      return { snack, status };
    },
    async deleteStep({ commit, state }, payload) {
      const { status, snack } = await useAxios(
        `/deleteStep/${payload}`,
        "put",
        true,
        null,
        true
      );
      if (status >= 200 && status < 300) {
        const newState = state.steps.filter((step) => {
          return step.id !== payload;
        });
        commit("setSteps", newState);
      }
      return { status, snack };
    },
    async getStepsSelectedByDefaultNoSelected({ state }, selecteds) {
      return state.steps.filter((step, index) => {
        const match = selecteds.find((item) => {
          return item.name === step.name;
        });

        if (!match && step.is_default === 1) {
          const stepSelected = {
            id: step.id,
            name: step.name,
            description: step.description,
            ordering: index + 1,
            is_public: step.is_public_by_default,
            selected: true,
            isNew: false,
          };
          return stepSelected;
        }
      });
    },
  },
  getters: {
    getAllStepsSelectedByDefault(state) {
      return state.steps.map((step, index) => {
        const stepSelected = {
          id: 0,
          id_step: step.id,
          name: step.name,
          description: step.description,
          order: index + 1,
          is_public: step.is_public_by_default,
          selected: true,
          isNew: false,
        };
        return stepSelected;
      });
    },
    getStepsForSelect(state) {
      return state.steps.map((step) => {
        return { value: step.id, text: step.name };
      });
    },    
  },
};
