export const ui = {
    state: {
        loader: false,
        snackbar: false,
        snackbarMessage: '',
        snackbarColor: 'red',
        dialogShow: false,
        msgDialogConfirm: '',
        idItemDialog: '',
        navBarOpen: true
    },
    mutations: {
        setLoader(state, payload) {
            state.loader = payload;
        },
        setSnackbar(state, payload) {
            state.snackbar = payload;
        },
        setSnackbarMessage(state, payload) {
            state.snackbarMessage = payload;
        },
        setSnackbarColor(state, payload) {
            state.snackbarColor = payload;
        },
        setMsgDialogConfirm(state, payload) {
            state.msgDialogConfirm = payload;
        },
        setIdItemDialog(state, payload) {
            state.idItemDialog = payload;
        },
        setDialogShow(state) {
            state.dialogShow = !state.dialogShow;
        },
        setNavBarOpen(state) {
            state.navBarOpen = !state.navBarOpen;
        },
    },
    actions: {
        setLoaderAction({ commit }, payload) {
            commit('setLoader', payload.value);
        },
        setNavBarOpenAction({ commit }) {
            commit('setNavBarOpen');
        },
        setSnackbarAction({ commit }, payload) {
            commit('setSnackbar', payload);
            if (payload == true) {
                // setTimeout(() => {
                //     commit('setSnackbar', false);
                // }, 4000);
            }
        },
        setSnackbarMessageAction({ commit }, payload) {
            commit('setSnackbarMessage', payload);
            if (payload == true) {
                // setTimeout(() => {
                //     commit('setSnackbarMessage', false);
                // }, 4000);
            }
        },
        setSnackbarColorAction({ commit }, payload) {
            commit('setSnackbarColor', payload);
            if (payload == true) {
                // setTimeout(() => {
                //     commit('setSnackbarColor', false);
                // }, 4000);
            }
        },
        setDialogShowAction({ commit }) {
            commit('setDialogShow');
        },
        setIdItemDialogAction({ commit }, payload) {
            commit('setIdItemDialog', payload);
        },
        setMsgDialogConfirmAction({ commit }, payload) {
            commit('setMsgDialogConfirm', payload);
        },
    },
    getters: {
    }
};