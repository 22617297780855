import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import links from '../helpers/links'
import { publicRoutes } from './PublicRoutes';
import { protectedRoutes } from './ProtectedRoutes';

Vue.use(VueRouter)

const routes = publicRoutes.concat(protectedRoutes);
// AL FINAL AGREGAMOS RUTA POR DEFAULT
routes.push({
  path: '/*',
  name: 'NotFound',
  component: () => import('../views/NotFound.vue')
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const isLogged = await store.dispatch('loadUser');
  if (to.meta.protectedRoute) { // ES UNA RUTA PROTEGIDA Y POR LO TANTO RETORNA TRUE
    if (isLogged) { // DEVUELVE TRUE OR FALSE. TRUE SI USER EXISTE.
      next(); // deja avanzar a la pagina solicitada
    }
    else {
      router.push(links.login.login); // SI EL USER NO ESTA AUTENTICADO, LO ENVIA A LA PAGE DEL LOGIN
    }
  }
  else { // RUTA NO PROTEGIDA, SI ESTA LOGUEADO NO DEJA ACCEDER A LA RUTA PUBLICA
    if (isLogged) {
      router.push(links.home);
    } else {
      next();
    }
  }
});

export default router
