const links = {
    home: "/",
    user: {
        create: "/users/create",
        update: "/users/edit",
        list: "/users/list",
        profile: "/users/profile",
    },
    userRole: {
        create: "/roles/create",
        update: "/roles/edit",
        list: "/roles/list",
    },
    product: {
        create: "/products/create",
        update: "/products/edit",
        list: "/products/list",
    },
    project: { // OBRAS
        create: "/project/create",
        update: "/project/edit",
        list: "/project/list",
        detail: "/project/detail",
    },
    brand: {
        create: "/brands/create",
        update: "/brands/edit",
        list: "/brands/list",
    },
    step: {
        create: "/steps/create",
        update: "/steps/edit",
        list: "/steps/list",
    },
    login: {
        createToken: '/createToken',
        resetPassword:'/resetPassword',
        login:'/login'
    },
    profile: {                
        detail:'/profile'
    },
}

export default links;