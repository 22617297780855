import { useAxios } from "../../hooks/useAxios";
export const products = {
    state: {
        products: [],
        productSelected: null,
        statesToStep: []
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload;
        },
        setProductSelected(state, payload) {
            state.productSelected = payload;
        },
        setStatesToStep(state, payload) {
            state.statesToStep = payload;
        },
    },
    actions: {
        async setProductsAction({ commit }) {
            const { data, status, snack } = await useAxios("/getProducts", "get", true, null, false);
            commit('setProducts', data?.product);
            return { status, snack };
        },
        async setProductSelectedAction({ commit }, payload) {
            const { data, status, snack } = await useAxios(`getProduct/${payload}`, "get", true, null, false);
            if (status >= 200 && status < 300) {
                commit('setProductSelected', data?.product);
            }
            return { status, snack };
        },
        async setStatesToStepAction({ commit }) {
            const { data, status, snack } = await useAxios("/getStatesProjectStep", "get", true, null, false);
            commit('setStatesToStep', data?.states);
            return { status, snack };
        },
        async deleteProductAction({ commit, state }, payload) {
            const { status, snack } = await useAxios(`/deleteProduct/${payload}`, "put", true, null, true);
            if (status >= 200 && status < 300) {
                const newState = state.products.filter((product) => {
                    return product.id !== payload;
                });
                commit('setProducts', newState);
            }
            return { status, snack };
        },
        async createProduct({ commit }, payload) {
            const { status, snack } = await useAxios("/createProduct", "post", true, payload, true);
            return { status, snack };
        },
        async updateProduct({ commit }, payload) {
            const { status, snack } = await useAxios(`updateProduct`, "post", true, payload, true);
            return { status, snack };
        },
        removeProductSelectedAction({ commit }) {
            commit('setProductSelected', null);
        }
    },
    getters: {
        getProductsForSelect(state) {
            const productsToReturn = state?.products?.map((product) => {
                return { value: product.id, text: product.description };
            });
            return productsToReturn;
        },
        getStatesStepsForSelect(state) {
            const statesToReturn = state?.statesToStep?.map((state) => {
                return { value: state.id, text: state.description };
            });
            return statesToReturn;
        },

        getStateStepById(state) {
            return function (id) {
                return state.statesToStep.find((item) =>
                    id ? item.id === id : ''
                );

            };
        },
    },
};
