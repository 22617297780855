import { useAxios } from "../../hooks/useAxios";

export const projects = {
  state: {
    projects: [],
    projectSelected: null,
    stepProjectsSelected: null,
    projectTypes: [],
    typeSelected: [],
    projectRol: [],
    rolSelected: "",
    documentToEdit: null,
    drawerActivity: false,
    drawerLink: false,
    drawerFiles: false,
    drawerUpload: false,
    drawerNewProduct: false,
    drawerUpdateDocument: false,
  },
  mutations: {
    setProjects(state, payload) {
      state.projects = payload;
    },
    setProjectSelected(state, payload) {
      state.projectSelected = payload;
    },
    setStepProjectSelected(state, payload) {
      state.stepProjectsSelected = payload;
    },
    setProjectTypes(state, payload) {
      state.projectTypes = payload;
    },
    setProjectTypeSelected(state, payload) {
      state.typeSelected = payload;
    },
    setProjectRol(state, payload) {
      state.projectRol = payload;
    },
    setProjectRolSelected(state, payload) {
      state.rolSelected = payload;
    },
    setDrawerActivity(state, payload) {
      state.drawerActivity = payload;
    },
    setDrawerLink(state, payload) {
      state.drawerLink = payload;
    },
    setDrawerFiles(state, payload) {
      state.drawerFiles = payload;
    },
    setDrawerUpload(state, payload) {
      state.drawerUpload = payload;
    },
    setDrawerNewProduct(state, payload) {
      state.drawerNewProduct = payload;
    },
    setDrawerUpdateDocument(state, payload) {
      state.drawerUpdateDocument = payload;
    },
    setDocumentToEdit(state, payload) {
      state.documentToEdit = payload;
    }
  },
  actions: {
    setProjectSelectedAction({ commit }, payload) {
      commit("setProjectSelected", payload);
    },
    setDrawerActivityAction({ commit }, payload) {
      commit("setDrawerActivity", payload);
    },
    setDrawerLinkAction({ commit }, payload) {
      commit("setDrawerLink", payload);
    },
    setDrawerFilesAction({ commit }, payload) {
      commit("setDrawerFiles", payload);
    },
    setDrawerUploadAction({ commit }, payload) {
      commit("setDrawerUpload", payload);
    },
    setDrawerNewProductAction({ commit }, payload) {
      commit("setDrawerNewProduct", payload);
    },
    setDrawerUpdateDocumentAction({ commit }, payload) {
      commit("setDrawerUpdateDocument", payload);
    },
    setDocumentToEditAction({ commit }, payload) {
      commit("setDocumentToEdit", payload);
    },
    async getProjects({ commit }) {
      const { data, status, snack } = await useAxios(
        "/getProjects",
        "get",
        true,
        null,
        false
      );
      if (data?.data) {
        commit("setProjects", data.data);
      }
      return { status, snack };
    },
    async getProjectsByUser({ commit }, payload) {
      // payload = idUser
      const { data, status, snack } = await useAxios(
        `/getProjectsUser/${payload}`,
        "get",
        true,
        null,
        false
      );
      if (data?.data) {
        commit("setProjects", data.data);
      }
      return { status, snack };
    },
    async createProjectAction({ commit }, payload) {
      const { status, snack } = await useAxios(
        "/createProject",
        "post",
        true,
        payload,
        true
      );
      return { status, snack };
    },

    async setProjectTypesAction({ commit }) {
      const { data, status, snack } = await useAxios(
        "/getProjectTypes",
        "get",
        true,
        null,
        false
      );
      commit("setProjectTypes", data.project_types);
      return { status, snack };
    },
    async setProjectRolAction({ commit }) {
      const { data, status, snack } = await useAxios(
        "/getProjectRols",
        "get",
        true,
        null,
        false
      );
      commit("setProjectRol", data.project_rols);
      return { status, snack };
    },
    async deleteProject({ commit, state }, payload) {
      const { status, snack } = await useAxios(
        `/deleteProject/${payload}`,
        "put",
        true,
        null,
        true
      );
      if (status >= 200 && status < 300) {
        const newState = state.projects.filter((project) => {
          return project.project.id !== payload;
        });
        commit("setProjects", newState);
      }
      return { status, snack };
    },

    async getProjectById({ commit, state }, payload) {
      const { data, status, snack } = await useAxios(
        `getProject/${payload}`,
        "get",
        true,
        null,
        false
      );
      if (status >= 200 && status < 300) {
        if (state.stepProjectsSelected === null) {
          await commit("setStepProjectSelected", data?.steps[0] || null);
        } else {
          const stepSelected = data?.steps.find((item) => item?.step.id === state.stepProjectsSelected?.step.id);
          await commit("setStepProjectSelected", stepSelected);
        }
        commit("setProjectSelected", data);
      }
      return { status, snack };
    },
    async getProjectDetailById({ commit, state }, payload) {
      const { data, status, snack } = await useAxios(
        `getProjectDetailById/${payload.idProject}/${payload.idperson}`,
        "get",
        true,
        null,
        false
      );
      if (status >= 200 && status < 300) {
        if (state.stepProjectsSelected === null) {
          await commit("setStepProjectSelected", data?.steps[0] || null);
        } else {
          const stepSelected = data?.steps.find((item) => item?.step.id === state.stepProjectsSelected?.step.id);
          await commit("setStepProjectSelected", stepSelected);
        }
        commit("setProjectSelected", data);
      }
      return { status, snack };
    },
    async setStepProjectelectedAction({ commit, state }, payload) {
      let stepSelected = null;
      if (payload !== null) {
        stepSelected = state.projectSelected.steps.find(
          (item) => item.step.id === payload
        );
      }
      commit("setStepProjectSelected", stepSelected || null);
    },
    async updateProject({ commit }, payload) {
      const { snack, status } = await useAxios(
        `updateProject/${payload.id}`,
        "post",
        true,
        payload.data,
        true
      );
      return { snack, status };
    },
    async createComment({ commit }, payload) {
      const { snack, status } = await useAxios(
        `createComment`,
        "post",
        true,
        payload,
        true
      );
      return { snack, status };
    },
    async deleteComment({ commit }, payload) {
      const { snack, status } = await useAxios(
        `deleteComment/${payload}`,
        "delete",
        true,
        null,
        true
      );
      return { snack, status };
    },
    async createLink({ commit }, payload) {
      const { snack, status } = await useAxios(
        `createLink`,
        "post",
        true,
        payload,
        true
      );
      return { snack, status };
    },
    async createDocument({ commit }, payload) {
      const { snack, status } = await useAxios(
        `createDocumentProject`,
        "post",
        true,
        payload,
        true
      );
      return { snack, status };
    },
    async createDocumentStep({ commit }, payload) {
      const { snack, status } = await useAxios(
        `createDocumentStep`,
        "post",
        true,
        payload,
        true
      );
      return { snack, status };
    },
    async createProductStep({ commit }, payload) {
      const { snack, status } = await useAxios(
        `createProductStep`,
        "post",
        true,
        payload,
        true
      );
      return { snack, status };
    },
    async updatePublicProjectStep({ commit }, payload) {
      const formData = {
        is_public: payload.is_public,
      }
      const { snack, status } = await useAxios(
        `updatePublicProjectStep/${payload.id_step_project}`,
        "put",
        true,
        formData,
        true
      );
      return { snack, status };
    },
    async updateStateProjectStep({ commit }, payload) {
      const formData = {
        state: payload.state,
      }
      const { snack, status } = await useAxios(
        `updateStateProjectStep/${payload.id_step_project}`,
        "put",
        true,
        formData,
        true
      );
      return { snack, status };
    },
    async updateDocumentProject({ commit }, payload) {
      const formData = {
        id_document: payload.id_document,
        id_step: payload.id_step,
        is_public: payload.is_public,
        is_public_visor: payload.is_public_visor,
        person_id: payload.person_id,
      }
      const { snack, status } = await useAxios(
        `updateDocumentProject/${payload.id_project}`,
        "put",
        true,
        formData,
        true
      );      
      return { snack, status };
    },
    async deleteLink({ commit }, payload) {
      const { snack, status } = await useAxios(
        `deleteLink/${payload}`,
        "delete",
        true,
        null,
        true
      );
      return { snack, status };
    },
    async deleteFile({ commit }, payload) {
      const { snack, status } = await useAxios(
        `deleteDocumentProject/${payload}`,
        "put",
        true,
        null,
        true
      );
      return { snack, status };
    },
    async deleteDocumentStep({ commit }, payload) {
      const { snack, status } = await useAxios(
        `deleteDocumentStep/${payload}`,
        "put",
        true,
        null,
        true
      );
      return { snack, status };
    },
    async deleteProductStep({ commit }, payload) {
      const { snack, status } = await useAxios(
        `deleteProductStep/${payload}`,
        "delete",
        true,
        null,
        true
      );
      return { snack, status };
    },
    setProjectTypeSelectedAction({ commit }, payload) {
      commit("setProjectTypeSelected", payload);
    },
    setProjectRolSelectedAction({ commit }, payload) {
      commit("setProjectRolSelected", payload);
    },
    removeProjectRolSelected({ commit }) {
      commit("setProjectRolSelected", null);
    },
    reset({ state, commit }) { },
  },
  getters: {
    getProjectTypes(state) {
      return state.projectTypes.map((type) => {
        return {
          value: type.id,
          text: type.name,
        };
      });
    },
    getProjectRol(state) {
      return state.projectRol.map((rol) => {
        return {
          value: rol.id,
          text: rol.description,
        };
      });
    },
    getRolById(state) {
      return function (id) {
        return state.projectRol.find((rol) => rol.id === id);
      };
    },
    getProjectsByName(state) {
      return function (name) {
        const newState = state.projects.filter((project) =>
          project.project.name.toLowerCase().includes(name.toLowerCase())
        );
        return newState;
      };
    },
    getStepSelected(state) {
      return state.stepProjectsSelected;
    },
    getDocs(state) {
      state.stepProjectsSelected.documents
      return state.stepProjectsSelected.documents.filter((item) =>
        !item.name.toLowerCase().includes('jpg') && !item.link.toLowerCase().includes('png') && !item.link.toLowerCase().includes('jpeg') && !item.link.toLowerCase().includes('http')
      );
    },
    getLinks(state) {
      return state.stepProjectsSelected.documents.filter((item) => item.link.toLowerCase().includes('http'));
    },
    getImages(state) {
      return state.stepProjectsSelected.documents.filter((item) => item.link.toLowerCase().includes('jpg') || item.link.toLowerCase().includes('jpeg') || item.link.toLowerCase().includes('png'));
    },
    getStepsForSelectWithProject(state) {
      const stepsToReturn = state.projectSelected.steps.map((step) => {
        return { value: step.step.id, text: step.step.name };
      });
      return [{ value: 0, text: 'Sin asignar' }, ...stepsToReturn];
    },
    getProjectStepsForSelect(state) {
      const stepsToReturn = state.projectSelected.steps.map((step) => {
        return { value: step.step.id, text: step.step.name };
      });
      return stepsToReturn;
    },
    getAllDocuments(state) {
      const documentsProject = state.projectSelected.documents.map((currentValue) => {
        const documentToReturn = {
          ...currentValue,
          stepId: 0,
          stepName: "Sin asignar"
        }
        console.log();
        return documentToReturn;
      });
      let documentSteps = [];
      state.projectSelected.steps.forEach(step => {
        step.documents.forEach(document => {
          const newDocument = {
            ...document,
            stepId: step.step.id,
            stepName: step.step.name
          }
          documentSteps.push(newDocument);
        });
      });
      return documentsProject.concat(documentSteps);
    },
  },
};
